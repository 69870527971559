import React, { useEffect, useState, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment-timezone';

import timeConstants from 'constants/time';

import {
  useChat,
  useNotificationQuery,
  useTypingQuery,
} from '@connect/connect-xmpp';
import { Chat as UiChat } from '@connect/connect-ui-js';

import { AudioProvider, AudioManager } from '@connect/connect-audio-message-plugin';

import { getProfile } from 'store/user/selectors';

import { orderSessions } from 'store/clientSessions/actions';
import { ordersDetailsFetch, STATUS_PROGRESS, STATUS_COMPLETE } from 'store/orders/actions';

import { CalcHeightContext } from 'components/order/CalcHeightContext';

import { TAB_MESSAGES } from 'constants/order';

import { getStatus } from '../utils';

import { Message, MessageBox, HeightCalculations } from './components';
import ThemeUiProvider from './theme';

import './index.scss';

const OrderClientChat = ({
  order = {}, standalone = false, mobile = false, parentRef, showTab,
}) => {
  const {
    _id: orderId,
    writer_chat_message_at: writerChatMessageAt = '',
    jabber_node: node,
    jabber_server: to,
    join_chat_at: joinChatAt,
    first_join_chat_at: firstJoinChatAt,
  } = order;

  const dispatch = useDispatch();

  const profile = useSelector(getProfile);

  const clientChatConfig = {
    node,
    to,
  };
  const { chatHeight } = useContext(CalcHeightContext);
  const [updatedMessageIdList, setUpdatedMessageIdList] = useState(null);
  const [reCalcByChatBanner, toggleReCalcByChatBanner] = useState(false);
  const [isShowChat, toggleIsShowChat] = useState(true);

  const { count: unreadCount } = useNotificationQuery(clientChatConfig);
  const {
    reversedIdList: messageIdList,
    fetchPrevPage,
    isLoading,
    readAll,
    setIsShowNotification,
    mainMessageRefs,
    setIsChatFocused,
  } = useChat(clientChatConfig);

  const { isTyping, errorTyping } = useTypingQuery(clientChatConfig);
  const chatBanner = useRef(null);
  const chatRef = useRef({
    issueOrderBanner: document.querySelector('.order-tab-head .issue-banner'),
    alertBanner: document.querySelector('.suspicious-alert'),
    idleTimeout: null,
    mainMessageRefs: {},
    prevChatBannerRef: null,
  });

  const isListLoading = !messageIdList.length && isLoading;

  const renderBannerText = () => {
    if (mobile || isLoading) return null;

    let text = '';
    let extraText = '';
    let extraClasses = standalone ? 'client-chat__banner--standalone' : '';

    if (getStatus(order) === STATUS_COMPLETE) {
      text = 'The order was completed.';
      extraClasses = `${extraClasses} client-chat__banner--green`;
    } else {
      text = '<div>If you face any issues, please contact the Support team <a>via "Operator messages" tab.</a></div>';

      if (getStatus(order) === STATUS_PROGRESS) {
        extraText = '<div class="mb8">Sharing prices and/or personal information will result in a 100% fine.</div>';
      } else {
        extraText = '<div class="mb8">You need to confirm all obligatory steps before order acceptance. Until that, you can be rejected from chat.</div>';
      }
    }

    text = `${extraText}${text}`;


    if (!text) return null;

    return (
      <div
        ref={chatBanner}
        className={classnames('client-chat__banner', extraClasses)}
        onClick={(e) => {
          const { target: { tagName } } = e;
          if (tagName !== 'A') return;

          showTab(TAB_MESSAGES);
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  };

  const updateMsgList = (callFromTimeout) => {
    const joinChatAtTime = moment(joinChatAt).utc().valueOf();
    let firstJoinChatAtTime = null;

    if (firstJoinChatAt && firstJoinChatAt !== joinChatAt) {
      firstJoinChatAtTime = moment(firstJoinChatAt).utc().valueOf();
    }

    const writerChatMessageAtM = moment(writerChatMessageAt).utc();
    const joinChatAtM = moment(joinChatAt).utc();

    const diffJoinMsg = moment.duration(writerChatMessageAtM.diff(joinChatAtM)).asMinutes();

    const fakeMsgs = {
      connected_fake: { time: firstJoinChatAtTime || joinChatAtTime, id: 'chat_connected_fake' },
    };

    if (firstJoinChatAtTime) {
      fakeMsgs.re_connected_fake = { time: joinChatAtTime, id: 'chat_re_connected_fake' };
    }

    if ((diffJoinMsg > 4 || callFromTimeout)) {
      const timeForIdle = moment(joinChatAt).add(4, 'minutes');
      fakeMsgs.chat_idle_fake = { time: timeForIdle.utc().valueOf(), id: 'chat_idle_fake' };
    }

    const newMessageIdList = Object.values({
      ...chatRef.current.mainMessageRefs,
      ...fakeMsgs,
    }).sort((a, b) => b.time - a.time).map(i => i.id);

    setUpdatedMessageIdList(newMessageIdList);
  };

  const onSetIsShowNotification = (flag) => {
    setIsShowNotification(flag);
  };

  const onWindowBlur = () => {
    setTimeout(() => {
      if (document.visibilityState !== 'hidden') return;

      setIsChatFocused(false);
      toggleIsShowChat(false);
    }, 10);
  };

  const onWindowFocus = () => {
    setIsChatFocused(true);
    toggleIsShowChat(true);
  };

  useEffect(() => {
    if (isListLoading) return;
    chatRef.current.mainMessageRefs = mainMessageRefs;

    const mainMessageRefsValues = Object.values(mainMessageRefs) || [];

    const isViewConnectEvent = mainMessageRefsValues.find(msg => msg.event && msg.event.event_name === 'crm:chat_connected' && msg.event.event_params.freelancer_id === profile._id);

    if (!isViewConnectEvent && mainMessageRefsValues.length > 0 && writerChatMessageAt) {
      setUpdatedMessageIdList(null);
      return;
    }

    updateMsgList();
  }, [isListLoading, mainMessageRefs, writerChatMessageAt, messageIdList]);

  useEffect(() => {
    if (Boolean(writerChatMessageAt) && chatRef.current.idleTimeout) {
      clearTimeout(chatRef.current.idleTimeout);
    }
  }, [writerChatMessageAt]);

  useEffect(() => {
    const { prevChatBannerRef } = chatRef.current;
    if (prevChatBannerRef !== chatBanner.current) {
      toggleReCalcByChatBanner(_prevValue => !_prevValue);
      chatRef.current.prevChatBannerRef = chatBanner.current;
    }
    if (standalone) {
      window.scrollTo(0, 0);
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
    }

    return () => {
      document.body.style.removeProperty('overflow');
    };
  });

  useEffect(() => {
    dispatch(orderSessions(orderId));
    dispatch(ordersDetailsFetch(orderId, { withId: true, quite: true }));

    const { ONE_MINUTE } = timeConstants;
    const fourMinutes = ONE_MINUTE * 4;

    if (!writerChatMessageAt) {
      const diffNowJoinAt = moment.duration(moment().diff(joinChatAt)).asMilliseconds();
      if (diffNowJoinAt > fourMinutes) {
        updateMsgList(true);
      } else {
        chatRef.current.idleTimeout = setTimeout(() => {
          updateMsgList(true);
        }, (fourMinutes - diffNowJoinAt));
      }
    }

    window.addEventListener('blur', onWindowBlur);
    window.addEventListener('focus', onWindowFocus);

    return () => {
      clearTimeout(chatRef.current.idleTimeout);
      window.removeEventListener('blur', onWindowBlur);
      window.removeEventListener('focus', onWindowFocus);
    };
  }, []);

  return (
    <div className={classnames('client-chat', { 'client-chat--standalone': standalone })}>
      {renderBannerText()}
      <div style={{ height: `calc(100vh - ${chatHeight})` }}>
        <ThemeUiProvider>
          <AudioProvider>
            {isShowChat && (
              <UiChat
                readAllMessages={readAll}
                unreadCount={unreadCount}
                messageIdList={updatedMessageIdList || messageIdList}
                renderMessage={messageId => <Message profile={profile} order={order} messageId={messageId} showTab={showTab} />}
                renderMessageBox={() => <MessageBox parentRef={parentRef} profile={profile} order={order} standalone={standalone} mobile={mobile} />}
                onEndReached={fetchPrevPage}
                isListLoading={isListLoading}
                setIsShowNotification={onSetIsShowNotification}
                isTyping={!errorTyping && !!isTyping}
                styles={{ height: '100%' }}
              />
            )}
            <AudioManager />
          </AudioProvider>
        </ThemeUiProvider>
      </div>
      <HeightCalculations order={order} standalone={standalone} chatBanner={chatBanner} reCalcByChatBanner={reCalcByChatBanner} />
    </div>
  );
};

export default OrderClientChat;
